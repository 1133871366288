const palette = {
  primary: "#00034d",
  primary_dark: "#0228ff",
  primary_light: "#3e8cff",
  navy: "#04014d",
  secondary: "#5246a2",
  secondary_light: "#6C5DD3",
  dark: "#0b5e9c",
  dark_blue: "#0b5e9c",
  blue_turquoise: "#2b9dc9",
  blue_turquoise_light: "#abe6ff",
  info: "#74b9ff",
  info_light: "#b4d5f7",
  transparent: "transparent",
  success: "#70d34b",
  danger: "#d8204e",
  warning: "#ffbe36",
  warning_dark: "#ffa25f",
  navigation_active_tint: "#00205c",
  navigation_inactive_tint: "#7f8190",
  background_color: "#F0F0F0",
  blocked: "#d3362b",
  orange: "#f9690e",
  coral: "#ff7f50",
  purple: "#5b0589",
  purple_job: "#a36cdb",
  light_purple: "#f4edfb",
  white: "#ffffff",
  blue: "#004CE3",
  blue_link: "#0644B7",
  blue_selected: "#007BBE",
  blue_sky: "#54B7EC",
  blue_project: "#1D7AB2",
  primary_blue_marker: "#0037FF",
  sec_blue_marker: "#99AFD8",
  primary_grey_marker: "#B1B1B1",
  slider_thumb_blue: "#3579B9",
  sec_grey_marker: "#DFDFDF",
  light_blue: "#EFF1FC",
  light_blue_project: "#EAF1F6",
  green: "#149712",
  green_checkmark: "#72c501",
  light_green: "#EEF7F0",
  lime_green: "#73c501",
  yellow: "#EDBB00",
  yellow_notes: "#FFF1CB",
  light_yellow: "#FFFCE5",
  red: "#FF0000",
  dark_red: "#C00600",
  icon_grey: "#bdbec5",
  light_red: "#F8F0EF",
  label_grey: "#727272",
  highlight_grey: "#858585",
  medium_grey: "#8e909e",
  dark_medium_grey: "#979797",
  track_grey: "#B5B5B5",
  light_grey: "#d8d8d8",
  lighter_grey: "#eee",
  lightest_grey: "#f5f5f5",
  bg_light_grey: "#FAFBFC",
  slightly_light_grey: "#c8c8c8",
  placeholder_grey: "#C1C1C1",
  line_item_grey: "#90a1b7",
  grey: "#7f8191",
  dark: "#444444",
  dark_grey: "#8d8e90",
  darker_grey: "#5c5c5c",
  black: "#000000",
  black_text: "#333333",
  border_black: "#505050",
  grey_blue: "#5a6b8b",
  bg_light_blue: "#E8F0FF",
  sticky_yellow: "#FDEDAF",
  grey_selection: "#EFF3FB",
  grey_bg: "#FBFBFB",
  label_red: "#f76862",
  bg_red: "#fcc5c3",
  transparent_grey_light: "rgba(0,0,0,0.25)",
  transparent_grey_dark: "rgba(0,0,0,0.75)",
  dark_blueish_grey: "#46515b",
  box_shadow: "0 0 5px rgba(0, 0, 0, 0.2)",
};

export default palette;
