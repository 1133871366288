import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onBackgroundMessage,
} from "firebase/messaging";
import {
  FIREBASE_API_KEY,
  FIREBASE_VAPID_KEY,
} from "../constants/app/index.web";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "engagetwiliofirebase.firebaseapp.com",
  projectId: "engagetwiliofirebase",
  storageBucket: "engagetwiliofirebase.appspot.com",
  messagingSenderId: "704139307474",
  appId: "1:704139307474:web:f4dc3c7fad34649f2ac00b",
  measurementId: "G-7FKE3LN7FV",
};

export const firebase = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export const firebaseMessaging = getMessaging(firebase);

export const getFCMToken = async () => {
  return getToken(firebaseMessaging, {
    vapidKey: FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        // Show permission request UI
        Notification.requestPermission((result) => {
          console.log(result);
          if (result == "granted") {
            localStorage.setItem("FCM_WEB_PUSH_GRANTED", true);
          } else {
            localStorage.removeItem("FCM_WEB_PUSH_GRANTED");
          }
        });
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
