import { useEffect, useRef } from "react";

/**
 * useEffect-like hook, but won't invoke the function on the first render.
 * Credit to: https://stackoverflow.com/a/53180013
 */
export default function useDidUpdateEffect(fn, dependencies) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    } else {
      didMountRef.current = true;
    }
  }, dependencies);
}
