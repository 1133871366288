import { StyleSheet } from "react-native";

const commonStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  alignCenter: {
    alignItems: "center",
  },
  pl4: {
    paddingLeft: 8,
  },
  pr4: {
    paddingRight: 8,
  },
  ml4: {
    marginLeft: 8,
  },
  mr4: {
    marginRight: 8,
  },
  mt4: {
    marginTop: 8,
  },
  mb4: {
    marginBottom: 8,
  },
  row_normal: {
    flexDirection: "row",
  },
  row_center: {
    alignItems: "center",
    flexDirection: "row",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  gap: {
    gap: 8,
  },
});

export default commonStyles;
