import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

let _CACHED = {
  API_TOKEN: null,
  ENGAGE_TOKEN: null,
  ENGAGE_USER: null,
  DEVICE: null,
  TWO_FACTOR_LAST_AUTH: null,
};

export const loadAppFromCacheAsync = async () => {
  const cache = await Promise.all([
    loadApiTokenAsync(),
    loadEngageTokenAsync(),
    loadEngageUserAsync(),
    loadDeviceAsync(),
  ]);
  return {
    apiToken: cache[0],
    engageToken: cache[1],
    user: cache[2],
    device: cache[3],
  };
};

export const clearAsync = async () => {
  const asyncStorageKeys = await AsyncStorage.getAllKeys();
  if (asyncStorageKeys?.length > 0) {
    if (Platform.OS === "android" || Platform.OS === "web") {
      const keys = asyncStorageKeys.filter(
        (key) => key !== "TWO_FACTOR_LAST_AUTH",
      );
      await AsyncStorage.multiRemove(keys);
    }
    if (Platform.OS === "ios") {
      await AsyncStorage.multiRemove(asyncStorageKeys);
    }
  }
};

// Auth Cache Helpers
export const loadDeviceAsync = async () => {
  let device = null;
  if (_CACHED["DEVICE"]) {
    return _CACHED["DEVICE"];
  }

  const deviceData = await AsyncStorage.getItem("DEVICE");

  try {
    if (deviceData) device = JSON.parse(deviceData);
  } catch (e) {
    console.log("Error loading device from storage.");
    await AsyncStorage.removeItem("DEVICE");
  }
  return (_CACHED["DEVICE"] = device);
};

export const loadApiTokenAsync = async () => {
  if (_CACHED["API_TOKEN"]) {
    return _CACHED["API_TOKEN"];
  }

  return (_CACHED["API_TOKEN"] = AsyncStorage.getItem("API_TOKEN"));
};

export const loadEngageTokenAsync = async () => {
  if (_CACHED["ENGAGE_TOKEN"]) {
    return _CACHED["ENGAGE_TOKEN"];
  }

  return (_CACHED["ENGAGE_TOKEN"] = AsyncStorage.getItem("ENGAGE_TOKEN"));
};

export const loadEngageUserAsync = async () => {
  let user = null;
  if (_CACHED["ENGAGE_USER"]) {
    return _CACHED["ENGAGE_USER"];
  }

  const userData = await AsyncStorage.getItem("ENGAGE_USER");
  try {
    if (userData) user = JSON.parse(userData);
  } catch (e) {
    console.log("Error loading user from storage.");
    clearAsync();
  }
  return (_CACHED["ENGAGE_USER"] = user);
};

export const clearApiTokenAsync = async () => {
  _CACHED["API_TOKEN"] = null;
  return await AsyncStorage.removeItem("API_TOKEN");
};

export const persistDeviceAsync = async (device) => {
  if (device) {
    try {
      const deviceJson = JSON.stringify(device);
      const res = await AsyncStorage.setItem("DEVICE", deviceJson);

      _CACHED["DEVICE"] = device;

      return res;
    } catch (e) {
      console.error("Failed to persist device.", e);
    }
  }
};

export const persistApiTokenAsync = async (token) => {
  if (window.localStorage) {
    window.localStorage.setItem("token", token);
  }

  if (token) {
    try {
      const res = await AsyncStorage.setItem("API_TOKEN", token);
      _CACHED["API_TOKEN"] = token;
      return res;
    } catch (e) {
      console.error("Failed to persist token.", e);
    }
  }
};

export const persistEngageTokenAsync = async (token) => {
  if (typeof window === "undefined" || window.localStorage) {
    console.warn("LocalStorage should not store secure tokens in web browser.");
  }

  if (token) {
    try {
      const res = await AsyncStorage.setItem("ENGAGE_TOKEN", token);
      _CACHED["ENGAGE_TOKEN"] = token;
      return res;
    } catch (e) {
      console.error("Failed to persist engage token.", e);
    }
  }
};

export const persistEngageUserAsync = async (user) => {
  if (user) {
    try {
      const userJson = JSON.stringify(user);
      const res = await AsyncStorage.setItem("ENGAGE_USER", userJson);
      _CACHED["ENGAGE_USER"] = user;
      return res;
    } catch (e) {
      console.error("Failed to persist user.", e);
    }
  }
};

export const persistLastMFAAsync = async (lastMFA) => {
  if (lastMFA) {
    try {
      const res = await AsyncStorage.setItem("TWO_FACTOR_LAST_AUTH", lastMFA);
      _CACHED["TWO_FACTOR_LAST_AUTH"] = lastMFA;
      return res;
    } catch (e) {
      console.error("Failed to persist user.", e);
    }
  }
};

export const loadLastMFAAsync = async () => {
  if (_CACHED["TWO_FACTOR_LAST_AUTH"]) {
    return _CACHED["TWO_FACTOR_LAST_AUTH"];
  }

  return (_CACHED["TWO_FACTOR_LAST_AUTH"] = AsyncStorage.getItem(
    "TWO_FACTOR_LAST_AUTH",
  ));
};
