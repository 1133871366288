import React, { forwardRef, useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import useDidUpdateEffect from "~/hooks/useDidUpdateEffect";
import palette from "../../styles/palette";

const styles = StyleSheet.create({
  otp_input__container: {
    flexDirection: "row",
    alignSelf: "center",
  },
  otp_input__digit_input_container: {
    borderColor: palette.light_grey,
    borderRadius: 4,
    borderWidth: 1,
    marginHorizontal: 4,
  },
  otp_input__digit_input_text: {
    width: 44,
    height: 44,
    fontSize: 18,
    textAlign: "center",
  },
});

const OTPInput = forwardRef(
  ({ autoFocus, numeric, onValueChange, style }, ref) => {
    const input0Ref = useRef();
    const input1Ref = useRef();
    const input2Ref = useRef();
    const input3Ref = useRef();
    const input4Ref = useRef();
    const input5Ref = useRef();
    const inputRefs = [
      input0Ref,
      input1Ref,
      input2Ref,
      input3Ref,
      input4Ref,
      input5Ref,
    ];
    const [values, setValues] = useState(["", "", "", "", "", ""]);

    useEffect(() => {
      if (autoFocus) {
        input0Ref.current.focus();
      }

      if (ref) {
        ref.current = {
          clear: () => {
            setValues(["", "", "", "", "", ""]);
            input0Ref.current.focus();
          },
        };
      }
    }, []);

    useDidUpdateEffect(() => {
      onValueChange(values.join(""));
    }, [values]);

    const onInputValueChange = (value, index) => {
      setValues((values) => {
        const newValues = [...values];
        newValues[index] = value;
        return newValues;
      });

      switch (index) {
        case 0:
          if (value) {
            input1Ref.current.focus();
          }
          break;
        case 1:
          if (value) {
            input2Ref.current.focus();
          } else {
            input0Ref.current.focus();
          }
          break;
        case 2:
          if (value) {
            input3Ref.current.focus();
          } else {
            input1Ref.current.focus();
          }
          break;
        case 3:
          if (value) {
            input4Ref.current.focus();
          } else {
            input2Ref.current.focus();
          }
          break;
        case 4:
          if (value) {
            input5Ref.current.focus();
          } else {
            input3Ref.current.focus();
          }
          break;
        case 5:
          if (!value) {
            input4Ref.current.focus();
          }
          break;
      }
    };

    return (
      <View style={[styles.otp_input__container, style]}>
        {inputRefs.map((ref, index) => (
          <DigitInput
            ref={ref}
            key={index}
            index={index}
            numeric={numeric}
            value={values[index]}
            onValueChange={onInputValueChange}
          />
        ))}
      </View>
    );
  },
);

const DigitInput = forwardRef(
  ({ index, numeric, value, onValueChange }, ref) => {
    const keyboardType = numeric ? "numeric" : "default";

    const onChangeText = (text) => {
      if (numeric) {
        text = text.replace(/[^0-9]/g, "");
      }
      onValueChange(text, index);
    };

    return (
      <View style={styles.otp_input__digit_input_container}>
        <TextInput
          ref={ref}
          value={value}
          onChangeText={onChangeText}
          maxLength={1}
          keyboardType={keyboardType}
          style={styles.otp_input__digit_input_text}
        />
      </View>
    );
  },
);

export default OTPInput;
