import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { sortByTimestamp } from "../helpers/sort";
import useCache from "../hooks/useCache";
import Engage from "../services/engage";
import NumbersContext from "./NumbersContext";
import UserContext from "./UserContext";
import useToggle from "../hooks/useToggle";
import { playMessageTone } from "../helpers/playMessageTone";
import isEqual from "lodash.isequal";
import useNumbersSubscription from "../hooks/useNumbersSubscription";
import { createNestedObject, getUrlParams } from "../helpers/text";
import { CONVERSATION_UPDATE_CHANNEL } from "../constants/conversations";
import DayJs from "../helpers/day";

const ThreadsContext = createContext({});

export function ThreadsProvider({ initialFilter = "assigned", children }) {
  const [threads, setThreads] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setActiveFilter] = useState();
  const {
    activeNumber,
    managedNumbers,
    loading: loadingNumbers,
  } = useContext(NumbersContext);
  const {
    loggedIn,
    user,
    isMessageToneEnabled,
    isDisturbMode,
    cacheLoading: loadingUser,
  } = useContext(UserContext);
  const [lineSelectVisible, toggleLineSelectVisible] = useToggle(false);
  const inboxPageRef = useRef(false);

  const [
    conversationDraftsCache,
    setConversationDraftsCache,
    isConversationDraftsReady,
  ] = useCache("ConversationContext.DRAFTS", { total: 0, drafts: {} });

  const [lineFilterCache, setLineFilterCache, isLineFilterCacheReady] =
    useCache("ThreadsContext.FILTER", {
      filter: initialFilter,
      user_number_id: user?.assigned_number?.id,
      managedNumbers: managedNumbers?.map((number) => number?.id),
    });

  const [selectedLines, setSelectedLines, isSelectedLinesCacheReady] = useCache(
    "ThreadsContext.SELECTED_LINES",
    [activeNumber?.id],
  );

  const getThreads = useCallback(async () => {
    if (!loading) {
      setLoading(true);

      let params = {
        filter: "managed",
      };

      if (filter === "all") {
        params.managed_lines = managedNumbers.map((num) => num?.id);
      } else if (filter === "assigned") {
        params.managed_lines = [user?.assigned_number?.id];
      } else {
        params.managed_lines = selectedLines || [];
      }

      if (!params?.managed_lines || !params?.managed_lines?.length) {
        setThreads([]);
      } else {
        const res = await Engage.getConversations({
          page,
          ...params,
        });
        if (res.error) {
          setError(res.message);
        } else {
          setTotal(res.total);
          const data = sortByTimestamp(res.response);
          setThreads(page === 1 ? data : [...threads, ...data]);
        }
      }
      setLoading(false);
    }
  }, [
    setLoading,
    filter,
    page,
    setError,
    setThreads,
    selectedLines,
    user?.assigned_number?.id,
    managedNumbers?.map((num) => num.id),
    loadingNumbers,
    loadingUser,
  ]);

  const refreshThreads = useCallback(async () => {
    setLoading(true);
    const res = await Engage.getConversations({
      filter,
      page: 1,
      managed_lines: selectedLines,
    });
    setPage(1);
    if (res.error) {
      setError(res.message);
    } else {
      const data = sortByTimestamp(res.response);
      setThreads(data);
    }
    setLoading(false);
  }, [setError, setThreads, filter, selectedLines]);

  const shouldAddThreadToList = useCallback(
    (twilio_number_id) => {
      let managed_lines = [];
      if (!loadingNumbers) {
        if (filter === "all") {
          managed_lines = managedNumbers?.map((num) => num?.id);
        } else if (filter === "assigned") {
          managed_lines = [user?.assigned_number?.id];
        } else {
          managed_lines = selectedLines;
        }
        if (managed_lines.includes(twilio_number_id)) {
          return true;
        }
      }
      return false;
    },
    [
      filter,
      selectedLines,
      user?.assigned_number?.id,
      managedNumbers?.map((num) => num.id),
      loadingNumbers,
    ],
  );

  const updateThread = useCallback(
    (thread) => {
      let existingThread = threads.find((t) => t.id === thread.id);
      if (!existingThread) {
        const twilio_number_id = thread?.twilio_number?.id;
        if (shouldAddThreadToList(twilio_number_id)) {
          setThreads((threads) => [thread, ...threads]);
        }
        return { thread_found: false, changed: false };
      }

      let changed = false;
      setThreads(
        threads
          .map((t) => {
            if (thread.id == t.id) {
              try {
                if (t?.timestamp && thread?.timestamp) {
                  const newTimeStamp = thread.timestamp;
                  const oldTimeStamp = t.timestamp;
                  const newDay = DayJs(newTimeStamp);
                  const oldDay = DayJs(oldTimeStamp);
                  const isTimeStampDifferent = !newDay.isSame(
                    oldDay,
                    "seconds",
                  );
                  const isTextDifferent =
                    t?.preview_obj?.text !== thread?.preview_obj?.text;
                  const isTypeDifferent =
                    t?.preview_obj?.interactable_type !==
                    thread?.preview_obj?.interactable_type;
                  if (
                    isTimeStampDifferent ||
                    isTextDifferent ||
                    isTypeDifferent
                  ) {
                    changed = true;
                  }
                }
              } catch {}
              return { ...t, ...thread };
            }
            return t;
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
      );

      return { thread_found: true, changed };
    },
    [threads, setThreads, shouldAddThreadToList],
  );

  const prevPage = useCallback(() => {
    if (page === 1 || loading) return;
    setPage((page) => page - 1);
  }, [loading]);

  const nextPage = useCallback(() => {
    if (loading) return;

    if (threads?.length !== total) {
      setPage((page) => page + 1);
    }
  }, [loading, threads, total]);

  useEffect(() => {
    if (
      filter &&
      !lineSelectVisible &&
      inboxPageRef?.current &&
      !loadingUser &&
      !loadingNumbers
    )
      getThreads();
  }, [
    filter,
    page,
    selectedLines,
    lineSelectVisible,
    inboxPageRef?.current,
    loadingUser,
    loadingNumbers,
  ]);

  const playMessageSound = useCallback(
    (payload) => {
      const direction = payload?.preview_obj?.direction?.includes("outbound")
        ? "outbound"
        : "inbound";
      const isMessage =
        payload?.preview_obj?.interactable_type === "TwilioMessage";

      if (
        isMessageToneEnabled &&
        !isDisturbMode &&
        direction === "inbound" &&
        isMessage
      ) {
        playMessageTone(require("../assets/sounds/new-message.mp3"));
      }
    },
    [isMessageToneEnabled, isDisturbMode, playMessageTone],
  );

  const pusherConversationUpdateHandler = useCallback(
    (data) => {
      try {
        if (data) {
          const parsedData = getUrlParams(`?${data}`, true);
          let params = {};
          Object.keys(parsedData).map((key) => {
            const splitData = key.split(".");
            const value = parsedData[key];
            createNestedObject(params, splitData, value);
          });

          if (params.participant) {
            params.participant.is_child =
              params.participant?.is_child === "true" || false;
            params.participant.valid =
              params.participant?.valid === "true" || false;
            params.participant.remote_id =
              parseInt(params.participant?.remote_id) || null;
            params.participant.is_blocked =
              params.participant?.is_blocked === "true" || false;
          }

          if (params.preview_obj) {
            params.preview_obj.sent =
              params.preview_obj?.sent === "true" || false;
            params.preview_obj.image =
              params.preview_obj?.image === "true" || false;
            params.preview_obj.missed =
              params.preview_obj?.missed === "true" || false;

            params.preview_obj.author.remote_id =
              parseInt(params.preview_obj.author?.remote_id) || null;
          }

          params.unread_count =
            typeof params.unread_count === "string"
              ? parseInt(params?.unread_count)
              : params?.unread_count || 0;
          params.read = params?.read === "true" || false;

          const { changed } = updateThread(params);

          // play message sound
          if (changed) {
            playMessageSound(params);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [updateThread, refreshThreads, playMessageSound],
  );

  const initialLoading = useMemo(() => loading && page === 1, [loading, page]);

  const getAllConversationDrafts = useCallback(() => {
    if (isConversationDraftsReady)
      return Object.keys(conversationDraftsCache.drafts);
    else {
      console.error("Conversation Draft Cache is not ready");
      return "";
    }
  }, [isConversationDraftsReady, conversationDraftsCache]);

  const getConversationDraft = useCallback(
    (id) => {
      if (isConversationDraftsReady) {
        return conversationDraftsCache.drafts[id];
      } else {
        console.error("Conversation Draft Cache is not ready");
        return "";
      }
    },
    [isConversationDraftsReady, conversationDraftsCache],
  );

  const setConversationDraft = useCallback(
    (id) => (value) => {
      if (isConversationDraftsReady)
        if (value.length > 0) {
          setConversationDraftsCache({
            ...conversationDraftsCache,
            drafts: { ...conversationDraftsCache.drafts, [id]: value },
          });
        } else if (value.length === 0 && conversationDraftsCache.drafts[id]) {
          let duplicateDrafts = conversationDraftsCache.drafts;
          delete duplicateDrafts[id];
          setConversationDraftsCache({
            ...conversationDraftsCache,
            drafts: duplicateDrafts,
          });
        } else if (value.length > 0 && isConversationDraftsReady)
          console.error("Conversation Draft Cache is not ready");
    },
    [isConversationDraftsReady, conversationDraftsCache],
  );

  const clearConversationDrafts = () => {
    if (isConversationDraftsReady)
      setConversationDraftsCache({ total: 0, drafts: {} });
  };

  const onFilterChange = useCallback(
    (filter) => {
      setPage(() => 1);
      setLineFilterCache({
        filter: filter,
        user_number_id: user?.assigned_number?.id,
        managedNumbers: managedNumbers?.map((number) => number?.id),
      });
    },
    [isLineFilterCacheReady, user?.assigned_number?.id, managedNumbers],
  );

  const onSelectedLinesChange = useCallback(
    (selectedLinesFunc) => {
      if (isSelectedLinesCacheReady) {
        try {
          if (typeof selectedLinesFunc === "function") {
            const res = selectedLinesFunc(selectedLines);
            setSelectedLines(res);
          } else {
            setSelectedLines(selectedLinesFunc);
          }
        } catch {}
      }
    },
    [isSelectedLinesCacheReady, managedNumbers?.map((num) => num?.id)],
  );

  useEffect(() => {
    if (isLineFilterCacheReady) {
      setActiveFilter(() => {
        if (
          isEqual(
            lineFilterCache?.managedNumbers,
            managedNumbers?.map((number) => number?.id),
          ) &&
          user?.assigned_number?.id === lineFilterCache?.user_number_id
        ) {
          return lineFilterCache?.filter;
        } else {
          return "assigned";
        }
      });
    }
  }, [
    isLineFilterCacheReady,
    lineFilterCache?.filter,
    user?.assigned_number?.id,
    managedNumbers?.map((number) => number?.id),
  ]);

  useEffect(() => {
    try {
      if (!loadingNumbers && managedNumbers?.length > 0) {
        const managed_number_ids = managedNumbers?.map((number) => number?.id);
        const numbers_not_found = selectedLines?.filter(
          (id) => !managed_number_ids?.includes(id),
        );
        if (numbers_not_found?.length > 0) {
          setSelectedLines([]);
        }
      }
    } catch {}
  }, [loadingNumbers]);

  useEffect(() => {
    if (!loggedIn && lineFilterCache?.filter) {
      setLineFilterCache({ filter: initialFilter });
    }
  }, [loggedIn, lineFilterCache.filter]);

  const setPageActive = (active) => {
    inboxPageRef.current = active;
  };

  useNumbersSubscription(
    CONVERSATION_UPDATE_CHANNEL,
    pusherConversationUpdateHandler,
  );

  return (
    <ThreadsContext.Provider
      value={{
        getThreads,
        refreshThreads,
        setFilter: onFilterChange,
        prevPage,
        nextPage,
        threads,
        filter,
        page,
        loading,
        error,
        initialLoading,
        getAllConversationDrafts,
        setConversationDraft,
        clearConversationDrafts,
        getConversationDraft,
        isConversationDraftsReady,
        conversationDrafts: Object.keys(conversationDraftsCache.drafts) || [],
        selectedLines,
        setSelectedLines: onSelectedLinesChange,
        isLineFilterCacheReady:
          isLineFilterCacheReady && isSelectedLinesCacheReady,
        lineSelectVisible,
        toggleLineSelectVisible,
        setPageActive,
        updateThread,
      }}
    >
      {children}
    </ThreadsContext.Provider>
  );
}

export default ThreadsContext;
