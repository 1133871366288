import UserContext from "../../../contexts/UserContext";
import { useContext, useEffect } from "react";
import SolidButton from "../../../components/Buttons/SolidButton";
import { saveAs } from "file-saver";
import { StyleSheet, Text, View } from "react-native";
import palette from "../../../styles/palette";
import { Strings } from "../../../constants/strings";
import Row from "../../../layouts/Row";
import Heading from "../../../components/Heading/index.web";
import commonStyles from "../../../styles/common";
import { useHistory } from "react-router-dom";
import IconButton from "../../../components/Buttons/IconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import FieldPulseLogo from "../../../components/Logo/FieldpulseLogo";

const styles = StyleSheet.create({
  model_container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    position: "absolute",
  },
  model_view: {
    backgroundColor: palette.white,
    paddingVertical: 18,
    paddingHorizontal: 24,
    borderRadius: 8,
    maxWidth: 650,
    boxShadow: palette.box_shadow,
    borderWidth: 1,
    borderColor: palette.light_grey,
  },
  mfa_recovery_code_message: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    fontSize: 16,
  },
  mfa_recovery__heading_container: {
    justifyContent: "space-between",
  },
});

const BACKUP_CODES_STRINGS = Strings.TWO_FA.BACKUP_CODES;

export function MFADownloadBackupScreen() {
  const { mfaRecoveryCodes, clearMfaRecoveryCodes } = useContext(UserContext);
  const history = useHistory();

  const onDownloadPress = async () => {
    try {
      let fileContent = "";
      for (let i = 0; i < mfaRecoveryCodes.length; i++) {
        if (i % 2 === 1) {
          fileContent += "        ";
        }
        fileContent += mfaRecoveryCodes[i];
        if (i % 2 === 1) {
          fileContent += "\n";
        }
      }
      const blob = new Blob([fileContent], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "FieldPulseRecoveryCodes.txt");
    } catch {}
  };

  const onContinuePress = () => {
    // This will trigger the MainNavigator to change stack
    clearMfaRecoveryCodes();
  };

  useEffect(() => {
    if (!mfaRecoveryCodes.length) {
      history.push("/");
    }
  }, [mfaRecoveryCodes]);

  return (
    <View style={styles.model_container}>
      <View style={styles.model_view}>
        <FieldPulseLogo />
        <DownloadBackupCodesHeading onClose={onContinuePress} />
        <Buttons
          onDownloadPress={onDownloadPress}
          onContinuePress={onContinuePress}
        />
      </View>
    </View>
  );
}

const DownloadBackupCodesHeading = ({ onClose }) => {
  return (
    <>
      <Row style={styles.mfa_recovery__heading_container}>
        <Heading size={2}>{BACKUP_CODES_STRINGS.GENERAL_HEADING}</Heading>
        <IconButton faPro faProIcon={faXmark} onPress={onClose} />
      </Row>
      <Text style={styles.mfa_recovery_code_message}>
        {BACKUP_CODES_STRINGS.GENERAL_MESSAGE}
      </Text>
    </>
  );
};

const Buttons = ({ onDownloadPress, onContinuePress }) => {
  return (
    <Row mt={24} style={commonStyles.gap}>
      <SolidButton
        label="Download Codes"
        color={palette.success}
        onPress={onDownloadPress}
      />
      <SolidButton
        label="Continue to Engage"
        border={0.5}
        color={palette.white}
        onPress={onContinuePress}
      />
    </Row>
  );
};

export default MFADownloadBackupScreen;
