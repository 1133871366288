import { Text, View, StyleSheet } from "react-native";
import Col from "../../../layouts/Col";
import Row from "../../../layouts/Row";
import palette from "../../../styles/palette";
import commonStyles from "../../../styles/common";
import Heading from "../../../components/Heading/index.web";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { Strings } from "../../../constants/strings";
import CustomRecordingsContext from "../../../contexts/CustomRecordingsContext";
import { useContext, useEffect, useMemo, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
import StyledSelect from "../../../components/StyledSelect";
import SolidButton from "../../../components/Buttons/SolidButton";
import Engage from "../../../services/engage";
import UserContext from "../../../contexts/UserContext";

const linkStyle = {
  color: palette.primary_light,
  fontFamily: "OpenSans_600SemiBold",
  textDecorationStyle: "none",
};

const styles = StyleSheet.create({
  loading_audio_samples: {
    backgroundColor: palette.bg_light_grey,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
  },
});

export default function HoldSettingsScreen() {
  return (
    <Col>
      <Row
        pl={16}
        pr={16}
        style={{
          height: 65,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Col leftCenter>
          <View style={commonStyles.row_center}>
            <Heading size={5} pb={8} mb={0}>
              {Strings.HOLD_SETTINGS.GENERAL_HEADING}
            </Heading>
            <InfoIconButton
              link={Strings.HOLD_SETTINGS.HOLD_SETTINGS_DOCS_URL}
            />
          </View>
        </Col>
      </Row>
      <HoldAudio />
    </Col>
  );
}

const HoldAudio = () => {
  const { user, getUser } = useContext(UserContext);
  const { customRecordings, getCustomRecordings, loading } = useContext(
    CustomRecordingsContext,
  );

  const [saving, setSaving] = useState(false);

  const [selected, setSelected] = useState(
    user?.account?.hold_music_url || "-1",
  );

  const options = [
    {
      label: "Select Audio Sample",
      value: "-1",
      isDisabled: true,
    },
    ...customRecordings
      ?.filter((audio) => audio?.url !== null)
      ?.map((recording) => ({
        label: recording.title,
        value: recording.url,
      })),
  ];

  const onSavePress = async () => {
    try {
      setSaving(true);
      const res = await Engage.updateHoldMusic(selected);
      if (!res?.error) {
        getUser();
      }
    } finally {
      setSaving(false);
    }
  };

  const isSaveDisabled = useMemo(() => {
    return (
      !selected ||
      selected === "-1" ||
      selected === user?.account?.hold_music_url
    );
  }, [selected, user?.account?.hold_music_url]);

  useEffect(() => {
    getCustomRecordings();
  }, []);

  return (
    <Row pl={16}>
      <Row mt={16}>
        <Col>
          <Heading size={4}>
            {Strings.HOLD_SETTINGS.HOLD_AUDIO.GENERAL_HEADING}
          </Heading>
          <Text>{Strings.HOLD_SETTINGS.HOLD_AUDIO.GENERAL_MESSAGE}</Text>
        </Col>
      </Row>
      <Row mt={16}>
        {loading && (
          <View style={styles.loading_audio_samples}>
            <Heading size={5} mb={0} numberOfLines={null}>
              Loading Audio Samples
            </Heading>
          </View>
        )}
        {!loading && options.length === 0 && (
          <Heading size={5} mb={0} numberOfLines={null}>
            {Strings.AUDIO_SAMPLES.NO_AUDIO_SAMPLE_MESSAGE}
            <Link to="/settings/voicenotes" style={linkStyle}>
              <Text>here</Text>
            </Link>
            .
          </Heading>
        )}
        {!loading && options.length > 0 && (
          <Col>
            <Row>
              <StyledSelect
                options={options}
                selectedValue={selected}
                onValueChange={setSelected}
                border
              />
            </Row>
            {selected && selected !== "-1" && (
              <Row mt={16}>
                <ReactAudioPlayer src={selected} controls />
              </Row>
            )}
          </Col>
        )}
      </Row>
      <Row mt={16}>
        <SolidButton
          onPress={onSavePress}
          loading={saving}
          color={palette.primary}
          disabled={isSaveDisabled}
          label="Save"
        />
      </Row>
    </Row>
  );
};
