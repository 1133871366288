import { useContext, useRef, useState } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import UserContext from "../../../contexts/UserContext";
import palette from "../../../styles/palette";
import Row from "../../../layouts/Row";
import Heading from "../../../components/Heading/index.web";
import { Strings } from "../../../constants/strings";
import OTPInput from "../../../components/OTPInput";
import LinkButton from "../../../components/Buttons/LinkButton";
import SolidButton from "../../../components/Buttons/SolidButton";
import Col from "../../../layouts/Col";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import IconButton from "../../../components/Buttons/IconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import FieldPulseLogo from "../../../components/Logo/FieldpulseLogo";

const styles = StyleSheet.create({
  model_container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    position: "absolute",
  },
  model_view: {
    backgroundColor: palette.white,
    paddingVertical: 18,
    paddingHorizontal: 24,
    borderRadius: 8,
    maxWidth: 650,
    boxShadow: palette.box_shadow,
    borderWidth: 1,
    borderColor: palette.light_grey,
    minWidth: 500,
  },
  mfa_recovery_code_message: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    fontSize: 16,
  },
  mfa_verification__error_message_text: {
    color: palette.red,
    textAlign: "center",
    marginTop: 16,
  },
  mfa_verification__switch_mode_text: {
    marginTop: 16,
  },
  mfa_verification__link_button: {},
  mfa_verification__heading_container: { justifyContent: "space-between" },
});

const TWO_FA_STRINGS = Strings.TWO_FA;
const VERIFICATION_CODE_STRINGS = Strings.VERIFICATION_CODE;

function MFAVerifyScreen() {
  const history = useHistory();
  const { mfaTempToken, clearMFAtokenAndQRCode } = useContext(UserContext);
  const [shouldUseRecoveryCode, setShouldUseRecoveryCode] = useState(false);

  useEffect(() => {
    if (!mfaTempToken) {
      history.replace("/");
    }
  }, [mfaTempToken]);

  return (
    <View style={styles.model_container}>
      <View style={styles.model_view}>
        <FieldPulseLogo />
        <MFAVerifyHeading
          shouldUseRecoveryCode={shouldUseRecoveryCode}
          onClose={clearMFAtokenAndQRCode}
        />
        <VerifyCode
          shouldUseRecoveryCode={shouldUseRecoveryCode}
          setShouldUseRecoveryCode={setShouldUseRecoveryCode}
        />
      </View>
    </View>
  );
}

const MFAVerifyHeading = ({ shouldUseRecoveryCode, onClose }) => {
  return (
    <>
      <Row style={styles.mfa_verification__heading_container}>
        <Heading size={2}>{TWO_FA_STRINGS.GENERAL_HEADING}</Heading>
        <IconButton faPro faProIcon={faXmark} onPress={onClose} />
      </Row>
      {!shouldUseRecoveryCode && (
        <Text style={styles.mfa_recovery_code_message}>
          {VERIFICATION_CODE_STRINGS.GENERAL_MESSAGE}
        </Text>
      )}
    </>
  );
};

const VerifyCode = ({ shouldUseRecoveryCode, setShouldUseRecoveryCode }) => {
  const history = useHistory();
  const { mfaVerifyCode, mfaVerifyRecoveryCode } = useContext(UserContext);
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSwitchTextVisible, setIsSwitchTextVisible] = useState(true);

  const otpInputRef = useRef();

  const onConfirmPress = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      let res;
      if (shouldUseRecoveryCode) {
        res = await mfaVerifyRecoveryCode(verificationCode);
      } else {
        res = await mfaVerifyCode("login", verificationCode);
      }
      if (res.error) {
        otpInputRef.current.clear();
        setErrorMessage(res.errors?.[0] ?? "Error verifying code");
        if (
          !shouldUseRecoveryCode &&
          (res.errorCode === 9 || res.error_code === 9)
        ) {
          setShouldUseRecoveryCode(true);
          setIsSwitchTextVisible(false);
        }
      } else {
        if (shouldUseRecoveryCode) {
          history.replace("/mfa-setup");
        } else {
          history.replace("/");
        }
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onSwitchTextPress = () => {
    setShouldUseRecoveryCode(!shouldUseRecoveryCode);
    setErrorMessage("");
    otpInputRef.current.clear();
  };

  return (
    <Row mt={24}>
      <Col center>
        <Text style={[styles.mfa_setup_steps_message, { marginBottom: 12 }]}>
          {shouldUseRecoveryCode
            ? VERIFICATION_CODE_STRINGS.RECOVERY_CODE_LABEL
            : VERIFICATION_CODE_STRINGS.VERIFICATION_CODE_LABEL}
        </Text>
        <OTPInput
          ref={otpInputRef}
          autoFocus
          numeric={!shouldUseRecoveryCode}
          onValueChange={setVerificationCode}
        />
        <SolidButton
          label={VERIFICATION_CODE_STRINGS.CONFIRM_BUTTON_LABEL}
          style={{
            marginTop: 24,
          }}
          color={palette.dark_blue}
          onPress={onConfirmPress}
          loading={isLoading}
          disabled={isLoading || verificationCode.length !== 6}
        />
        {!!errorMessage && (
          <Text style={styles.mfa_verification__error_message_text}>
            {errorMessage}
          </Text>
        )}
        {isSwitchTextVisible && (
          <Text style={styles.mfa_verification__switch_mode_text}>
            {shouldUseRecoveryCode
              ? VERIFICATION_CODE_STRINGS.OPTIONAL_MESSAGE_CODE
              : VERIFICATION_CODE_STRINGS.OPTIONAL_MESSAGE_RECOVERY_CODE}
            <LinkButton
              onPress={onSwitchTextPress}
              textStyle={{ fontSize: 13 }}
              style={styles.mfa_verification__link_button}
            >
              {shouldUseRecoveryCode
                ? VERIFICATION_CODE_STRINGS.USE_CODE
                : VERIFICATION_CODE_STRINGS.USE_RECOVERY_CODE}
            </LinkButton>
          </Text>
        )}
      </Col>
    </Row>
  );
};

export default MFAVerifyScreen;
