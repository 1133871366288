import React from "react";
import { Image, TouchableOpacity } from "react-native";

export default function FieldPulseLogo({ onPress }) {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={onPress ? 0.5 : 1}>
      <Image
        source={require("~/assets/loading-logo-v2.svg")}
        style={{
          height: 76,
          width: 304,
        }}
      />
    </TouchableOpacity>
  );
}
