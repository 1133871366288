import { useContext, useEffect, useState } from "react";
import { MAIN_WEBAPP_URLS } from "../constants/app/index.web";
import { WebAppPostMessage } from "../models/WebAppPostMessage";
import UserContext from "../contexts/UserContext";

export default function UseMainAppAuthShare() {
  const [isListening, setIsListening] = useState(false);
  const { loginWithFpToken, cacheLoading, setLogoutError } =
    useContext(UserContext);

  const onLogoutUser = () => {
    setLogoutError(true);
  };

  const handlePostMessage = (e) => {
    try {
      if (MAIN_WEBAPP_URLS.includes(e?.origin)) {
        const payload = e?.data;
        if (!payload || !payload?.type) return;
        const webAppPostMessageEvent = new WebAppPostMessage(
          payload.type,
          payload,
        );
        if (webAppPostMessageEvent.isMainAppAuthEvent) {
          webAppPostMessageEvent.handleEvent(
            e,
            () => {}, // emptyFunction
            () => {}, // emptyFunction
            () => {}, // emptyFunction
            () => {}, // emptyFunction
            () => {}, // emptyFunction
            () => {}, // emptyFunction
            loginWithFpToken,
            onLogoutUser,
          );
        }
      }
    } catch (error) {
      console.error("Error handling post message:", error);
    }
  };

  useEffect(() => {
    if (!cacheLoading) {
      window.addEventListener("message", handlePostMessage);
      setIsListening(true);
    }
  }, [cacheLoading]);

  useEffect(() => {
    if (isListening) {
      const webAppPostMessageEvent =
        WebAppPostMessage.createEngageReadyStatusEvent();
      webAppPostMessageEvent.emitEvent();
    }
  }, [isListening]);
}
