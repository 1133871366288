import { Image, StyleSheet, Text } from "react-native";
import { View } from "react-native";
import palette from "../../../styles/palette";
import Row from "../../../layouts/Row";
import Heading from "../../../components/Heading/index.web";
import { Strings } from "../../../constants/strings";
import { useContext, useRef, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import QRCode from "react-qr-code";
import Col from "../../../layouts/Col";
import OTPInput from "../../../components/OTPInput";
import SolidButton from "../../../components/Buttons/SolidButton";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../../../components/Buttons/IconButton";
import FieldPulseLogo from "../../../components/Logo/FieldpulseLogo";

const styles = StyleSheet.create({
  model_container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    position: "absolute",
  },
  model_view: {
    backgroundColor: palette.white,
    paddingVertical: 18,
    paddingHorizontal: 24,
    borderRadius: 8,
    maxWidth: 650,
    boxShadow: palette.box_shadow,
    borderWidth: 1,
    borderColor: palette.light_grey,
  },
  mfa_setup_steps_label: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  mfa_setup_steps_message: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    fontSize: 16,
  },
  mfa_setup__error_message_text: {
    color: palette.red,
    textAlign: "center",
    marginTop: 16,
  },
  mfa_setup__heading_container: { justifyContent: "space-between" },
});

const TWO_FA = Strings.TWO_FA;

function MFASetupScreen() {
  const history = useHistory();
  const { qrManualCode, qrCode, clearMFAtokenAndQRCode } =
    useContext(UserContext);

  useEffect(() => {
    if (!qrCode) history.replace("/");
  }, [qrCode]);
  return (
    <View style={styles.model_container}>
      <View style={styles.model_view}>
        <FieldPulseLogo />
        <MFASetupHeading onClose={clearMFAtokenAndQRCode} />
        <Step1 />
        <Step2 />
        <MFAQrCode manualCode={qrManualCode} qrCode={qrCode} />
        <Step3 />
        <VerifyCode />
      </View>
    </View>
  );
}

const MFASetupHeading = ({ onClose }) => {
  return (
    <>
      <Row style={styles.mfa_setup__heading_container}>
        <Heading size={2}>{TWO_FA.GENERAL_HEADING}</Heading>
        <IconButton faPro faProIcon={faXmark} onPress={onClose} />
      </Row>
      <Row>
        <Text>{TWO_FA.REQUIRED_MESSAGE}</Text>
      </Row>
    </>
  );
};

const Step1 = () => {
  return (
    <Row mt={24} leftCenter>
      <Text style={styles.mfa_setup_steps_label}>
        {TWO_FA.STEP_1.LABEL}:{" "}
        <Text style={styles.mfa_setup_steps_message}>
          {TWO_FA.STEP_1.MESSAGE}
        </Text>
      </Text>
    </Row>
  );
};

const Step2 = () => {
  return (
    <Row mt={24} leftCenter>
      <Text style={styles.mfa_setup_steps_label}>
        {TWO_FA.STEP_2.LABEL}:{" "}
        <Text style={styles.mfa_setup_steps_message}>
          {TWO_FA.STEP_2.MESSAGE}
        </Text>
      </Text>
    </Row>
  );
};

const Step3 = () => {
  return (
    <Row mt={24} leftCenter>
      <Text style={styles.mfa_setup_steps_label}>
        {TWO_FA.STEP_3.LABEL}:{" "}
        <Text style={styles.mfa_setup_steps_message}>
          {TWO_FA.STEP_3.MESSAGE}
        </Text>
      </Text>
    </Row>
  );
};

const MFAQrCode = ({ manualCode, qrCode }) => {
  return (
    <Row center mt={24}>
      <Col center>
        <Image source={{ uri: qrCode }} style={{ width: 250, height: 250 }} />
        <Text style={[styles.mfa_setup_steps_message, { marginTop: 12 }]}>
          {TWO_FA.MANUAL_CODE_LABEL}: {manualCode}
        </Text>
      </Col>
    </Row>
  );
};

const VerifyCode = () => {
  const otpInputRef = useRef();
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { mfaVerifyCode } = useContext(UserContext);

  const onConfirmPress = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");

      const res = await mfaVerifyCode("setup", verificationCode);
      if (res.error) {
        otpInputRef.current.clear();
        setErrorMessage(res.errors?.[0] ?? "Error verifying code");
      } else {
        history.push("/mfa-recovery-codes");
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row mt={24}>
      <Col center>
        <Text style={[styles.mfa_setup_steps_message, { marginBottom: 12 }]}>
          {Strings.VERIFICATION_CODE.LABEL}
        </Text>
        <OTPInput
          ref={otpInputRef}
          numeric
          onValueChange={setVerificationCode}
        />
        <SolidButton
          label={Strings.VERIFICATION_CODE.CONFIRM_BUTTON_LABEL}
          style={{
            marginTop: 24,
          }}
          color={palette.dark_blue}
          onPress={onConfirmPress}
          loading={isLoading}
          disabled={isLoading || verificationCode.length !== 6}
        />
        {!!errorMessage && (
          <Text style={styles.mfa_setup__error_message_text}>
            {errorMessage}
          </Text>
        )}
      </Col>
    </Row>
  );
};

export default MFASetupScreen;
