import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FieldPulse, { hasToken } from "../services/fieldpulse";
import UserContext from "~/contexts/UserContext";
import { filterCustomer } from "~/helpers/customers";
import { Alert } from "react-native";
import Engage from "../services/engage";
import { ACCOUNT_TYPES } from "../constants/accountTypes";
import BlockedContactsContext from "./BlockedContactsContext";

const CustomersContext = createContext({
  loading: false,
  error: false,
  customers: [],
  limit: 50,
  page: 1,
  total: 0,
  active: null,
});

// NOTE: https://medium.com/@srbkrishnan/infinite-scroll-pagination-in-flatlist-with-hooks-and-function-components-c9c08bba23a8
const FPCustomerRequest = {
  all: FieldPulse.getCustomers,
  recent: FieldPulse.getRecentCustomers,
  assigned: FieldPulse.getAssignedCustomers,
};

export function CustomersProvider({
  filterBy = "recent",
  children,
  isWeb = false,
}) {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    filter: filterBy,
    limit: 50,
    // TODO: Fix this once we have the api returning the correct results
    total: 0,
    customers: [],
  });
  const [blocking, setBlocking] = useState(false);
  const [blockingError, setBlockingError] = useState(false);
  const [blockedContacts, setBlockedContacts] = useState([]);
  const {
    addBlockedContact: addBlockedParticipant,
    removeBlockedContact: removeBlockedParticipant,
  } = useContext(BlockedContactsContext);
  const { limit, filter } = state;
  const page = useRef(1);

  const getCustomers = useCallback(
    async (filterParam) => {
      if (filterParam) {
        updateFilter(filterParam);
      }

      const activeFilter = filterParam || filter;

      if (!loading && user?.remote_id) {
        setLoading(true);
        const res = await FPCustomerRequest[activeFilter]({
          page: page.current,
          limit,
          user_id: user?.remote_id,
          // validPhone: true
        });
        if (!res.error && res.response) {
          setState((state) => ({
            ...state,
            customers:
              page.current === 1
                ? res.response.map(filterCustomer)
                : [...state.customers, ...res.response.map(filterCustomer)],
            total: res.total_results,
          }));
        } else {
          setError(res.message);
        }
        setLoading(false);
      }
    },
    [user?.remote_id, filter, limit, updateFilter, setLoading],
  );

  const updateFilter = useCallback(
    (filterBy) => {
      if (loading) return;
      setState({
        ...state,
        filter: filterBy,
        customers: [],
      });
      page.current = 1;
    },
    [state, loading],
  );

  const setSearchSuggestions = useCallback(
    (suggestions) => {
      setState({
        ...state,
        customers: suggestions,
        total: suggestions.length,
      });
    },
    [state],
  );

  const prevPage = async () => {
    if (page.current === 1) return;
    page.current = page.current - 1;
    await getCustomers();
  };

  const nextPage = async () => {
    if (
      loading ||
      state?.customers?.length % limit !== 0 ||
      filter === "recent"
    )
      return;
    page.current = page.current + 1;
    await getCustomers();
  };

  const refreshCustomers = async () => {
    setState({ ...state, customers: [] });
    page.current = 1;
    await getCustomers();
  };

  useEffect(() => {
    if (!loading) {
      getCustomers();
    }
  }, [limit, filter]);

  const deleteCustomer = async (id) => {
    setLoading(true);
    const res = await FieldPulse.deleteCustomer({ id });
    await refreshCustomers();
    setLoading(false);
    return res;
  };

  const addBlockedContact = (id) => {
    if (!id) return;
    setBlockedContacts((blockedContacts) => {
      return [...blockedContacts, id];
    });
  };

  const removeBlockedContact = (id) => {
    if (!id) return;
    setBlockedContacts((blockedContacts) => {
      return blockedContacts.filter((contactId) => contactId !== id);
    });
  };

  const blockCustomer = async (customer) => {
    setBlocking(true);
    setBlockingError(false);
    let participant;
    let result = { error: false };

    try {
      if (customer?.customer_id) {
        const parentRes = await FieldPulse.getCustomer(customer.customer_id);
        if (!parentRes?.response) {
          result.error = true;
          throw new Error("Customer not found");
        }

        const parentCustomer = parentRes?.response;
        const res = await Engage.createParticipant({
          first_name:
            (parentCustomer?.account_type === ACCOUNT_TYPES[1].value
              ? parentCustomer?.company_name
              : parentCustomer?.first_name) || parentCustomer?.display_name,
          last_name:
            parentCustomer?.account_type === ACCOUNT_TYPES[1].value
              ? ""
              : parentCustomer?.last_name,
          email: parentCustomer?.email,
          phone_number: parentCustomer?.phone,
          remote_id: parentCustomer?.id,
        });

        if (!res?.error) {
          const parentParticipant = res?.response;

          const participantRes = await Engage.createParticipant({
            first_name: customer?.first_name,
            last_name: customer?.last_name,
            email: customer?.email,
            phone_number: customer?.phone,
            remote_id: customer?.id,
            parent_id: parentParticipant?.id,
          });

          if (participantRes?.error) {
            result.error = true;
            throw new Error("Participant not found");
          } else {
            participant = participantRes?.response;
          }
        } else {
          result.error = true;
          throw new Error("Failed to create participant");
        }
      } else if (customer?.id) {
        const res = await Engage.createParticipant({
          first_name:
            (customer?.account_type === ACCOUNT_TYPES[1].value
              ? customer?.company_name
              : customer?.first_name) || customer?.display_name,
          last_name:
            customer?.account_type === ACCOUNT_TYPES[1].value
              ? ""
              : customer?.last_name,
          email: customer?.email,
          phone_number: customer?.phone,
          remote_id: customer?.id,
        });

        if (!res.error) {
          participant = res?.response;
        } else {
          result.error = true;
          throw new Error("Failed to create participant");
        }
      } else if (customer?.phone) {
        const res = await Engage.createParticipant({
          phone_number: customer?.phone,
        });

        if (!res?.error) {
          participant = res?.response;
        } else {
          result.error = true;
          throw new Error("Participant not found");
        }
      } else {
        throw new Error("Invalid customer");
      }

      if (participant) {
        const blockId = participant?.id;
        if (blockId) {
          const res = await Engage.updateParticipant(blockId, {
            is_blocked: true,
          });

          if (!res.error) {
            addBlockedContact(blockId);
            addBlockedParticipant(participant);

            if (alert) Alert.alert("", "Contact successfully blocked.");
          } else {
            result.error = true;
            if (alert) Alert.alert("", "Contact could not be blocked.");
            setBlockingError(true);
          }
        } else {
          throw new Error("Participant not found");
        }
      }
    } catch (e) {
      result.error = true;
      setBlockingError(true);
      console.error("Error blocking customer:", e);
    } finally {
      setBlocking(false);
    }

    return result;
  };

  const blockContact = async (id, alert = true) => {
    setBlocking(true);
    setBlockingError(false);
    let res = {};
    try {
      const blockId = id;
      if (blockId) {
        res = await Engage.updateParticipant(blockId, {
          is_blocked: true,
        });

        if (!res.error) {
          addBlockedContact(blockId);

          if (alert) Alert.alert("", "Contact successfully blocked.");
        } else {
          if (alert) Alert.alert("", "Contact could not be blocked.");
          setBlockingError(true);
        }
      }
    } catch {
      res.error = true;
      setBlockingError(true);
    } finally {
      setBlocking(false);
    }
    return res;
  };

  const unBlockContact = async (id, alert = true) => {
    setBlocking(true);
    setBlockingError(false);
    let res = {};
    try {
      const unBlockId = id;
      if (unBlockId) {
        res = await Engage.updateParticipant(unBlockId, {
          is_blocked: false,
        });

        if (!res.error) {
          if (alert) Alert.alert("", "Contact successfully unblocked.");
          removeBlockedContact(unBlockId);
          removeBlockedParticipant(unBlockId);
        } else {
          if (alert) Alert.alert("", "Contact could not be unblocked.");
          setBlockingError(true);
        }
      }
    } catch {
      res.error = true;
      setBlockingError(true);
    } finally {
      setBlocking(false);
    }
    return res;
  };

  return (
    <CustomersContext.Provider
      value={{
        ...state,
        page: page.current,
        loading,
        error,
        setSearchSuggestions,
        getCustomers,
        nextPage,
        prevPage,
        filter,
        updateFilter,
        refreshCustomers,
        deleteCustomer,
        blockContact,
        unBlockContact,
        blockedContacts,
        blockingError,
        blocking,
        blockCustomer,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
}

export default CustomersContext;
