import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import palette from "../../../styles/palette";
const styles = StyleSheet.create({
  link_button__container: {
    alignSelf: "flex-start",
  },
  link_button__text: {
    color: palette.blue_selected,
    fontSize: 14,
    textAlign: "center",
  },
});

export default function LinkButton({
  children,
  loading,
  disabled,
  withoutTouchable,
  onPress,
  style,
  textStyle,
}) {
  const Wrapper = ({ children }) => {
    if (withoutTouchable) {
      return <>{children}</>;
    } else {
      return (
        <TouchableOpacity
          style={[styles.link_button__container, style]}
          disabled={!!disabled}
          activeOpacity={0.8}
          onPress={onPress}
        >
          {children}
        </TouchableOpacity>
      );
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <ActivityIndicator size="small" />
      ) : (
        <Text
          onPress={withoutTouchable ? onPress : null}
          style={[
            styles.link_button__text,
            withoutTouchable && style,
            textStyle,
          ]}
        >
          {children}
        </Text>
      )}
    </Wrapper>
  );
}
